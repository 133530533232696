<div style="width: 100%; height: 100%" (click)="parentDivClicked()">
  <ng-container *ngIf="widget$ | async as widget">
    <div class="export-button-container">
      <button
        nbTooltip="Export table to a CSV file"
        class="export-button"
        nbButton
        [nbSpinner]="isLoading$ | async"
        status="basic"
        ghost
        size="small"
        (click)="exportTable()"
      >
        <nb-icon icon="cloud-download-outline"></nb-icon> Export
      </button>
    </div>
    <ng-container *ngIf="tableInputData$ | async">
      <ag-grid-angular
        *ngIf="widget.config.pinnedColumns?.length"
        style="width: 100%; height: 100%"
        class="ag-theme-alpine"
        [rowData]="(tableInputData$ | async)?.data"
        [columnDefs]="(tableInputData$ | async)?.agColumnDefs"
        (gridReady)="onGridReady($event)"
        [gridOptions]="{ enableCellTextSelection: true, ensureDomOrder: true, }"
        [suppressFieldDotNotation]="true"
        (cellClicked)="onCellClicked($event)"
        (columnHeaderClicked)="onColumnHeaderClicked($event)"
        (rowDataChanged)="sizeColumns()"
        (gridSizeChanged)="sizeColumns()"
        (columnMoved)="onColumnMoved()"
      >
      </ag-grid-angular>
      <ag-grid-angular
        *ngIf="!widget.config.pinnedColumns?.length"
        style="width: 100%; height: 100%"
        class="ag-theme-alpine"
        [rowData]="(tableInputData$ | async)?.data"
        [columnDefs]="(tableInputData$ | async)?.agColumnDefs"
        (gridReady)="onGridReady($event)"
        [gridOptions]="{ enableCellTextSelection: true, ensureDomOrder: true, suppressHorizontalScroll: true, }"
        (rowDataChanged)="sizeColumns()"
        (gridSizeChanged)="sizeColumns()"
        [suppressFieldDotNotation]="true"
        (cellClicked)="onCellClicked($event)"
        (columnHeaderClicked)="onColumnHeaderClicked($event)"
        (columnMoved)="onColumnMoved()"
      >
      </ag-grid-angular>
    </ng-container>
  </ng-container>
</div>
