import {
  CompactType,
  DisplayGrid,
  GridsterConfig,
  GridType,
} from 'angular-gridster2';

/**
 * Generate default gridster config object
 * @param extra optional Partial<GridsterConfig> - will overwrite any default config props with the param props
 * @returns GridsterConfig
 */
export const getGridsterConfig = (
  extra: Partial<GridsterConfig> = {},
): GridsterConfig => {
  const config = {
    gridType: GridType.VerticalFixed,
    compactType: CompactType.None,
    margin: 10,
    outerMargin: false,
    outerMarginTop: null,
    outerMarginRight: null,
    outerMarginBottom: null,
    outerMarginLeft: null,
    useTransformPositioning: true,
    mobileBreakpoint: 420,
    minCols: 24,
    maxCols: 24,
    minRows: 24,
    maxRows: 500,
    maxItemCols: 24,
    minItemCols: 1,
    maxItemRows: 100,
    minItemRows: 1,
    maxItemArea: 2500,
    minItemArea: 1,
    defaultItemCols: 3,
    defaultItemRows: 3,
    fixedColWidth: 75,
    fixedRowHeight: 55,
    keepFixedHeightInMobile: true,
    keepFixedWidthInMobile: false,
    scrollSensitivity: 15,
    scrollSpeed: 30,
    enableEmptyCellClick: false,
    enableEmptyCellContextMenu: false,
    enableEmptyCellDrop: false,
    enableEmptyCellDrag: false,
    enableOccupiedCellDrop: false,
    emptyCellDragMaxCols: 24,
    emptyCellDragMaxRows: 100,
    ignoreMarginInRow: false,
    draggable: {
      enabled: false,
    },
    resizable: {
      enabled: false,
    },
    swap: true,
    pushItems: false,
    disablePushOnDrag: false,
    disablePushOnResize: false,
    pushDirections: { north: true, east: true, south: false, west: true },
    pushResizeItems: true,
    displayGrid: DisplayGrid.OnDragAndResize,
    disableWindowResize: false,
    disableWarnings: false,
    scrollToNewItems: true,
    disableScrollHorizontal: true,
  };

  return { ...config, ...extra };
};
