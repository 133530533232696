import {
  CalcDataSource,
  CalculationType,
  ICalculationData,
  ICalculationDataDictionary,
  ICalculationDataUpdate,
  ICalculationStateMap,
  ICalculationV2Map,
  ICalculationValueMap,
  IEzConfigMap,
  IFilterVariableBackendState,
  IFilterViewItem,
  IGetCalculationDataResponse,
  IStorableWidgetV2,
  IThresholdsV2,
  IThresholdV2,
  IWidgetColorMap,
  IWidgetColorMapDumb,
  IWidgetColors,
  IWidgetConfig,
  WidgetType,
} from '@interfaces';
import { Store } from '@ngxs/store';
import { GridsterItem } from 'angular-gridster2';
import { getDefaultChartColors } from 'environments/default-settings/default-settings';
import { IAppStateModel } from '../app.model';
import { CalculationStateObject } from './calculation.state-model';

export interface IWidgetStateObject extends IStorableWidgetV2 {
  isLoading: boolean;
  stateRef: Store;
  highlightState: WidgetHighlightState;
  fetchedInitialData?: boolean;
  activeFilterViewId?: string | null;
  readyToPlayThresholdSound?: boolean;
}

/**
 * - normal: The widget is not in a special state
 * - unselected: The widget is not selected, but is ready to be selected for a filter view item
 * - selected: The widget is selected for a filter view item
 * - disabled: The widget is disabled, and cannot be selected for a filter view item because it's already in use
 *          by another filter view item in the same filter view or not using the correct data stream.
 */
export type WidgetHighlightState =
  | 'normal'
  | 'unselected'
  | 'selected'
  | 'disabled';
export type IWidgetHighlightStateMapToCSSClass = {
  [highlightState in WidgetHighlightState]: string;
};
export const widgetHighlightStateToWidgetCSSClassMap: IWidgetHighlightStateMapToCSSClass =
  {
    normal: '',
    unselected: 'widget-unselected',
    selected: 'widget-selected',
    disabled: 'widget-disabled',
  };

interface IColorKey {
  calcId: string;
  label: string | null;
}
type ColorKeys = IColorKey[];

export class WidgetStateObject implements IWidgetStateObject {
  // IStorableWidgetV2
  public id: string;
  public widgetType: WidgetType;
  public gridsterItem: GridsterItem;
  public title: string;
  public description: string;
  public groupId: string; // The pointer to all linked copies of this widget
  public config: IWidgetConfig;
  public iFrameUrl?: string;
  public imageUrl?: string;
  public staticString?: string;
  public dynamicStringColumn?: string;
  public thresholds: IThresholdsV2;
  public thresholdsByCalculation: { [calcId: string]: IThresholdsV2 };
  public colors: IWidgetColors;
  public calculations: ICalculationStateMap;
  public allowedCalculationsType: CalculationType;
  public dashboardV2Id: string;
  public featureItemEnabled: boolean;

  // IWidgetStateObject
  public isLoading = false;
  public stateRef: Store;
  public highlightState: WidgetHighlightState = 'normal';
  public fetchedInitialData = false;
  public activeFilterViewId?: string | null = null;
  public readyToPlayThresholdSound = true;

  constructor(inputObj: Partial<IWidgetStateObject>, store: Store) {
    this.stateRef = store;
    // Get the active filter view
    let activeFilterViewId: string | null = null;
    let filterViewItems: IFilterViewItem[];
    const widgetEditor = store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.widgetEditor,
    );
    const inWidgetEditor =
      widgetEditor?.widgetId === inputObj.id && widgetEditor?.isOpen;

    if (!inWidgetEditor) {
      filterViewItems = this.getFilterViewItems(store);
      activeFilterViewId = store.selectSnapshot(
        (state) => (state.app as IAppStateModel).appState.currentDashboard,
      )?.activeFilterViewId;
    }

    const { currentFilterVariableState } = store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.filterVariableState,
    );

    for (const key of Object.keys(inputObj)) {
      if (key === 'calculations') {
        const calcs: ICalculationStateMap = {};
        for (const calcId in inputObj.calculations) {
          let inputCalc = inputObj.calculations[calcId];
          let calc: CalculationStateObject;
          // If the input calculation is not a CalculationStateObject, then create a new one
          if (inputCalc instanceof CalculationStateObject === false) {
            calc = new CalculationStateObject(inputCalc, store, this.id);
          } else if (inputCalc instanceof CalculationStateObject) {
            calc = inputCalc.reInitialize();
          } else {
            throw new Error('Invalid calculation object');
          }
          let subscriptionId = calcId;
          let usingChildFilter = false;
          // Check if there is an active filter view
          if (activeFilterViewId) {
            const childFilterId = this.getChildFilterIdFromFilterViewItems(
              filterViewItems,
              this.id,
              calcId,
            );
            if (childFilterId) {
              subscriptionId = childFilterId;
              usingChildFilter = true;
              // Since this calculation is being used by a filter view item, set the active filter view id
              this.setActiveFilterViewId(activeFilterViewId);
            }
          }
          subscriptionId = this.getSubscriptionIdFromFilterVariableState(
            currentFilterVariableState?.state,
            subscriptionId,
            usingChildFilter,
          );

          calc.setCalcDataId(subscriptionId);
          calcs[calcId] = calc;
        }
        this.calculations = calcs;
        continue;
      }
      this[key] = inputObj[key];
    }
  }

  public get calcGrouped(): boolean {
    return (
      this.config.grouping.value == 'groupByCalculation' ||
      this.config.grouping.value == 'groupByLabel'
    );
  }

  public get calcStacked(): boolean {
    return (
      this.config.grouping.value == 'stackByCalculation' ||
      this.config.grouping.value == 'stackByLabel'
    );
  }

  public get allCalcsHaveData() {
    if (!this.calculations) return true;
    const calcArr = Object.values(this.calculations);
    return calcArr.every((calc) => calc.hasData);
  }

  public get hasCalculations(): boolean {
    return !!this.calculations && Object.keys(this.calculations).length > 0;
  }

  public get hasSomeData(): boolean {
    if (!this.calculations) return false;
    const calcArr = Object.values(this.calculations);
    return calcArr.some((calc) => calc.hasData);
  }

  public get hasBrokenData(): boolean {
    if (!this.calculations) return true;
    const calcArr = this.calcArray;
    return calcArr.some((calc) => calc.hasBrokenData);
  }

  public get hasColumnInfo(): boolean {
    if (!this.calculations) return false;
    const calcArr = this.calcArray;
    return calcArr.some((calc) => calc.hasColumnInfo);
  }

  public get calcArray(): CalculationStateObject[] {
    if (!this.calculations) return [];
    if (this.config?.calculationOrder?.length) {
      return this.config.calculationOrder
        .map((calcId) => this.calculations[calcId])
        .filter((calc) => !!calc);
    }
    return Object.values(this.calculations);
  }

  public get isDataBasedWidget(): boolean {
    return (
      this.widgetType !== 'iFrame' &&
      this.widgetType !== 'image' &&
      this.widgetType !== 'staticString'
    );
  }

  public get hasModalData(): boolean {
    const calcArr = this.calcArray;
    return calcArr.some((c) => c.hasModalData);
  }

  public get getActiveFilterViewId(): string | null {
    return this.activeFilterViewId || null;
  }

  public get dataStreamIds(): string[] {
    let allCalcDataStreamIds = this.calcArray.map((calc) => calc.dataStreamId);
    // Remove duplicates
    allCalcDataStreamIds = Array.from(new Set(allCalcDataStreamIds));
    return allCalcDataStreamIds;
  }

  public get xAxisType(): 'category' | 'time' {
    return this.calcArray.every(
      (calc) => !calc.getActiveData()?.is_category_x_axis,
    )
      ? 'time'
      : 'category';
  }

  /**
   * @description Searches through a list of filter view items
   * and determines if this widget is in use by any of them.
   * Then returns the child filter id in the filter view item's widget-child-filter map
   * @param filterViewItems The list of filter view items to search through
   * @param widgetId
   * @param calculationId
   * @returns childFilterId | null
   */
  private getChildFilterIdFromFilterViewItems(
    filterViewItems: IFilterViewItem[],
    widgetId: string,
    calculationId: string,
  ): string | null {
    if (!filterViewItems) return null;

    const childFilterId = filterViewItems
      .map((item) =>
        item.widgetsAndChildFilters.map((wcf) => {
          if (wcf.widgetId === widgetId) {
            return wcf.childFilters.map((cf) => {
              if (cf.fkCalculationUuid === calculationId) {
                return cf.id;
              }
            });
          }
        }),
      )
      .flat(2)
      .find((id) => !!id);
    return childFilterId || null;
  }

  private getFilterVariableIdFromFilterViewItems(
    filterViewItems: IFilterViewItem[],
    widgetId: string,
    calculationId: string,
  ): string[] {
    if (!filterViewItems) return [];
    const filterVariableIds: string[] = [];
    filterViewItems.map((item) =>
      item.widgetsAndChildFilters.map((wcf) => {
        if (wcf.widgetId === widgetId) {
          return wcf.childFilters.map((cf) => {
            if (cf.fkCalculationUuid === calculationId) {
              const pandasFilter = item?.filter?.pandasFilter;
              if (pandasFilter) {
                const pandasFilterItems = pandasFilter.items;
                if (pandasFilterItems) {
                  return pandasFilterItems.map((pandasFilterItem) => {
                    if (
                      pandasFilterItem?.advancedValueType === 'filter_variable'
                    ) {
                      filterVariableIds.push(
                        pandasFilterItem?.advancedValue?.variable_id,
                      );
                    }
                  });
                }
              }
            }
          });
        }
      }),
    );
    return filterVariableIds;
  }

  /**
   *
   * @param filterVariableState
   * @param calcId
   * @param usingChildFilter
   * @description If usingChildFilter is true, then the calcId is the child filter id,
   * otherwise it's the calculation id
   */
  private getSubscriptionIdFromFilterVariableState(
    filterVariableState: IFilterVariableBackendState | null,
    calcId,
    usingChildFilter,
  ): string {
    if (!filterVariableState) return calcId;
    const subscriptionId =
      filterVariableState?.dashboardVariableStateToCalc.find(
        (dashboardVariableStateToCalc) => {
          if (usingChildFilter) {
            return dashboardVariableStateToCalc.childFilterId === calcId;
          } else {
            // Since we're searching only for variable states where the child filter id is null, we can ignore it
            if (dashboardVariableStateToCalc.childFilterId) return false;
            return dashboardVariableStateToCalc.calculationUuid === calcId;
          }
        },
      )?.id;
    return subscriptionId || calcId;
  }

  /**
   * @description Returns the ids of the filter variables that this widget is
   * being affected by. This is generated by getting the ids of the calculations,
   * running them through getSubscriptionIdFromFilterVariableState, checking if
   * the id changed, and if it did, then adding it to the list of ids.
   */
  public getFilterVariableIds(): string[] {
    const filterVariableIds: string[] = [];
    const filterViewItems = this.getFilterViewItems(this.stateRef);
    const calculations = this.calcArray;
    const currentFilterVariableState = this.stateRef.selectSnapshot(
      (state) =>
        (state.app as IAppStateModel).appState.filterVariableState
          ?.currentFilterVariableState,
    );
    // If we don't have a filter variable state, then we don't need to do anything
    if (!currentFilterVariableState) return filterVariableIds;

    calculations.map((calc) => {
      const calcFilterVariableIds = calc?.filterVariableIds || [];
      filterVariableIds.push(...calcFilterVariableIds);

      // Check if there is an active filter view
      if (this.activeFilterViewId) {
        const childFilterId = this.getChildFilterIdFromFilterViewItems(
          filterViewItems,
          this.id,
          calc.id,
        );
        if (childFilterId) {
          // If we have a child filter, then we can get all of variable
          // ids from the filter view items
          const childFilterVariableIds =
            this.getFilterVariableIdFromFilterViewItems(
              filterViewItems,
              this.id,
              calc.id,
            );
          filterVariableIds.push(...childFilterVariableIds);
        }
      }
    });
    return Array.from(new Set(filterVariableIds));
  }

  private getFilterViewItems(store: Store): IFilterViewItem[] | null {
    const currentDashboard = store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.currentDashboard,
    );
    if (currentDashboard?.activeFilterViewId) {
      const activeFilterViewId = currentDashboard.activeFilterViewId;
      const filterViewItems = store.selectSnapshot(
        (state) =>
          (state.app as IAppStateModel).appState.filterViews[activeFilterViewId]
            ?.filterViewItems,
      );
      if (!filterViewItems) return null;
      return filterViewItems;
    }
    return null;
  }

  public getEzConfigMap(): IEzConfigMap {
    const ezConfigMap: Partial<IEzConfigMap> = {};
    const config = this.config;
    if (!config) return;
    for (const key in config) {
      if (key === 'id') continue;

      // Check if the toggle property exists
      if (config[key]?.toggled !== undefined) {
        ezConfigMap[key] = config[key].toggled;
      }
    }
    return ezConfigMap as IEzConfigMap;
  }

  /**
   * Get the thresholds that would be visible on the chart
   */
  public getActiveThresholds(calcId: string): IThresholdV2[] {
    if (this.config.useCalculationThresholds) {
      if (calcId in this.thresholdsByCalculation) {
        return this.thresholdsByCalculation[calcId]?.custom?.sort(
          (a, b) => a.value - b.value,
        );
      } else {
        return [];
      }
    } else {
      return this.thresholds.custom.sort((a, b) => a.value - b.value);
    }
  }

  /**
   * Given a current and previous value, check if the threshold was hit.
   * If it was hit from the correct direction, return the threshold object
   */
  public checkIfThresholdWasHit(
    currentValue: number,
    calcId: string,
  ): IThresholdV2 | null {
    if (currentValue === undefined) return null;
    const activeThresholds = (
      this.getActiveThresholds(calcId) as IThresholdV2[]
    ).sort((a, b) => a.value - b.value);
    const thresholdsMeetingCriteria: IThresholdV2[] = [];
    for (let i = 0; i < activeThresholds.length; i++) {
      const threshold = activeThresholds[i];

      // A threshold comparator is either eq, gte, or lte
      const thresholdComparator = threshold.comparator;

      // Need to check if the threshold was met or surpassed and that the previous value was not
      if (thresholdComparator === 'eq') {
        if (currentValue === threshold.value) {
          thresholdsMeetingCriteria.push(threshold);
        }
      } else if (thresholdComparator === 'lte') {
        if (currentValue <= threshold.value) {
          thresholdsMeetingCriteria.push(threshold);
        }
      } else if (thresholdComparator === 'gte') {
        if (currentValue >= threshold.value) {
          thresholdsMeetingCriteria.push(threshold);
        }
      }
    }
    // If there are multiple thresholds that were hit, we return the one that has the closest value to the current value
    if (thresholdsMeetingCriteria.length > 0) {
      const closestThreshold = thresholdsMeetingCriteria.reduce(
        (prev, current) =>
          Math.abs(current.value - currentValue) <
          Math.abs(prev.value - currentValue)
            ? current
            : prev,
      );
      return closestThreshold;
    } else {
      return null;
    }
  }

  public getCurrentAndPreviousValuesForActiveCalcs(): ICalculationValueMap {
    const calcValues: ICalculationValueMap = {};
    const activeCalculations = this.calcArray;
    activeCalculations.forEach((calc) => {
      const calcId = calc.id;
      const calcData = calc.getActiveData();
      if (!calcData?.y?.length) return;
      calcValues[calcId] = calcData.y[calcData.y.length - 1]?.values?.[0];
      return;
    });
    return calcValues;
  }

  public getCalculation(calcId: string): CalculationStateObject | undefined {
    return this.calculations[calcId];
  }

  /**
   * We generate default colors for all the chart items, but we substitute the ones that have a
   * custom color set by the user. This way everything is consistent
   */
  public getAllColors(): IWidgetColorMap {
    const widgetColorMap: IWidgetColorMap = {};
    const widgetColors = this.colors;
    const allDefaultColors = this.getAllDefaultColors();
    const dashboardGlobalColors = this.stateRef.selectSnapshot(
      (state) =>
        (state.app as IAppStateModel).appState.currentDashboard
          ?.customLabelsAndColors,
    );

    Object.keys(allDefaultColors).forEach((calcId) => {
      const defaultColor = allDefaultColors[calcId];
      const calcColor = widgetColors[calcId];
      const customColor = calcColor?.color;
      const usesDefaultColor = calcColor ? calcColor.useDefault : true;
      widgetColorMap[calcId] = {};
      // Rename since we're sure it's an object now
      const defaultColors = defaultColor;
      Object.keys(defaultColors).forEach((label) => {
        // Check if the global color is being used for this label
        const globalColor = dashboardGlobalColors?.find((labelColor) => {
          const validModes = ['contains', 'equals'] as const;
          if (!validModes.includes(labelColor.mode)) {
            throw new Error(
              `Invalid mode: ${
                labelColor.mode
              }. Expected one of: ${validModes.join(', ')}`,
            );
          }
          const normalizedLabel = label.toLowerCase();
          const normalizedColorLabel = labelColor.label.toLowerCase();
          return labelColor.mode === 'contains'
            ? normalizedLabel.includes(normalizedColorLabel)
            : normalizedLabel === normalizedColorLabel;
        })?.color;

        if (globalColor && usesDefaultColor) {
          widgetColorMap[calcId][label] = globalColor;
          return;
        }

        const defaultDictColor = defaultColors[label];
        usesDefaultColor
          ? (widgetColorMap[calcId][label] = defaultDictColor)
          : (widgetColorMap[calcId][label] = customColor);
      });
    });

    return widgetColorMap;
  }

  public getAllDefaultColors(): IWidgetColorMap {
    const colorKeys = this.getColorKeys();
    const widgetColorMap: IWidgetColorMap = {};
    const defaultColors = getDefaultChartColors(0, colorKeys.length);
    colorKeys.forEach((colorKey, index) => {
      const defaultColor = defaultColors[index];
      if (!widgetColorMap[colorKey.calcId])
        widgetColorMap[colorKey.calcId] = {};
      widgetColorMap[colorKey.calcId][colorKey.label] = defaultColor;
    });
    return widgetColorMap;
  }

  public getColorKeys() {
    const colorKeys: ColorKeys = [];
    const calculations = this.calcArray;
    calculations.forEach((calc) => {
      const data = calc.getActiveData();
      if (!data) return;

      let sortedLabels: string[] = [];
      // If there aren't any grouping values, then we can just sort the x values
      if (data.is_category_x_axis) {
        sortedLabels = data.x.map((x) => x);
      }

      if (!data.x_groupings) {
        if (sortedLabels.length === 0) {
          sortedLabels = [calc.name];
        } else {
          sortedLabels.push(calc.name);
        }
      } else {
        sortedLabels = data.x_groupings;
        // Replace 'null' with Blank
        sortedLabels = sortedLabels.map((label) =>
          label === 'null' ? 'Blank' : label,
        );
      }
      for (const label of sortedLabels) {
        colorKeys.push({ calcId: calc.id, label });
      }
    });
    return colorKeys;
  }

  /**
   * Dumb colors are the same as getAllColors, but all dicts are reduced to a single color (first one)
   */
  public getAllDumbColors(): IWidgetColorMapDumb {
    const colorMap = this.getAllColors();
    const dumbColorMap: IWidgetColorMapDumb = {};
    Object.keys(colorMap).forEach((calcId) => {
      const color = colorMap[calcId];
      if (typeof color === 'string') {
        dumbColorMap[calcId] = color;
      } else {
        const firstColor = Object.values(color)[0];
        dumbColorMap[calcId] = firstColor;
      }
    });
    return dumbColorMap;
  }

  public getSingleColor(calcId: string, label: string): string | undefined {
    const colors = this.getAllColors();
    return colors?.[calcId]?.[label];
  }

  public getDefaultColor(calcId: string): string {
    const calc = this.getCalculation(calcId);
    if (!calc) throw new Error('Calculation not found');
    const allDefaultColors = this.getAllDefaultColors();
    const defaultColor = allDefaultColors[calcId];
    if (typeof defaultColor === 'object')
      throw new Error('Calculation is a dictionary calc');
    return defaultColor;
  }

  public getActiveData(calcId: string): ICalculationData | undefined {
    const calc = this.getCalculation(calcId);
    if (!calc) return;
    return calc.getActiveData();
  }

  public caresAboutDataId(dataId: string): boolean {
    const calcIds = Object.keys(this.calculations);
    const calc = calcIds.find((id) =>
      this.calculations[id].caresAboutCalcDataId(dataId),
    );
    return !!calc;
  }

  public setReadyToPlayThresholdSound(readToPlay: boolean) {
    this.readyToPlayThresholdSound = readToPlay;
  }

  public setCalcData(
    data:
      | ICalculationData
      | ICalculationDataDictionary
      | ICalculationDataUpdate
      | IGetCalculationDataResponse[],
    type: CalcDataSource,
  ) {
    const calcArr = this.calcArray;
    calcArr.forEach((c) => c?.setCalcData(data, type));
  }

  public setActiveFilterViewId(viewId: string) {
    this.activeFilterViewId = viewId;
  }

  public reInitialize(storableWidget?: IStorableWidgetV2) {
    // Declare a variable to hold the new widget state object
    let widget: WidgetStateObject;

    // Check if a storable widget is provided
    if (storableWidget) {
      // Create a new widget state object from the storable widget
      widget = new WidgetStateObject(storableWidget, this.stateRef);

      // Iterate over the properties of the storable widget
      for (const key in storableWidget) {
        // If the property is 'calculations', handle it separately
        if (key === 'calculations') {
          const calcs = storableWidget.calculations;
          const calcKeys = Object.keys(calcs);
          const existingCalcData: ICalculationDataDictionary = {};

          // Iterate over the calculation keys
          for (const calcKey of calcKeys) {
            const calc = calcs[calcKey];
            let calcState: CalculationStateObject;
            const currentCalc = this.calculations[calcKey];
            const activeCalcDataId = currentCalc?.activeCalcDataId;

            // If there is existing calculation data, store it
            if (currentCalc?.calculationData && activeCalcDataId) {
              existingCalcData[currentCalc.activeCalcDataId] =
                this.getActiveData(calcKey);
            }

            // Check if the input is a CalculationStateObject, if it is, reinitialize it
            if (calc instanceof CalculationStateObject) {
              calcState = calc.reInitialize(calc);
            } else if (
              currentCalc &&
              currentCalc instanceof CalculationStateObject
            ) {
              // If the current calculation is a CalculationStateObject, reinitialize it
              calcState = currentCalc.reInitialize(calc);
            } else {
              // Otherwise, create a new CalculationStateObject
              calcState = new CalculationStateObject(
                calc,
                this.stateRef,
                storableWidget.id,
              );
            }

            // If there is an active calculation data ID, set it
            if (activeCalcDataId) {
              calcState.setCalcDataId(activeCalcDataId);
            }

            // Add the calculation state to the widget
            widget.calculations[calcKey] = calcState;
          }

          // Set the existing calculation data on the widget
          widget.setCalcData(existingCalcData, 'rawCalcDataDict');

          // Skip the rest of the loop for this iteration
          continue;
        }

        // For other properties, simply copy them from the storable widget
        widget[key] = storableWidget[key];
      }
    } else {
      // If no storable widget is provided, create a new widget state object from 'this'
      widget = new WidgetStateObject(this, this.stateRef);
    }

    // Return the newly created or reinitialized widget
    return widget;
  }

  public hasAllCalcData() {
    const calculations = this.calcArray;
    return !calculations.some((c) => !c.hasData);
  }

  public toStorableWidget(): IStorableWidgetV2 {
    const storableCalculations: ICalculationV2Map = {};
    const calculations = this.calcArray;
    calculations.forEach((calc) => {
      storableCalculations[calc.id] = calc.toStorableCalculation();
    });
    return {
      id: this.id,
      widgetType: this.widgetType,
      gridsterItem: this.gridsterItem,
      title: this.title,
      description: this.description,
      groupId: this.groupId,
      config: this.config,
      iFrameUrl: this.iFrameUrl,
      imageUrl: this.imageUrl,
      staticString: this.staticString,
      dynamicStringColumn: this.dynamicStringColumn,
      thresholds: this.thresholds,
      thresholdsByCalculation: this.thresholdsByCalculation,
      colors: this.colors,
      calculations: storableCalculations,
      allowedCalculationsType: this.allowedCalculationsType,
      dashboardV2Id: this.dashboardV2Id,
      featureItemEnabled: this.featureItemEnabled,
    };
  }

  public getCopyTitle(): string {
    const title = this.title;
    if (!title) return 'Copy';
    let copyTitle;
    const lastCharacter = parseInt(title[title.length - 1]);
    const isAlreadyCopy =
      !isNaN(lastCharacter) && typeof lastCharacter === 'number';
    if (isAlreadyCopy) {
      const copyNumber = lastCharacter + 1;
      copyTitle = title.slice(0, title.length - 1) + copyNumber;
    } else {
      copyTitle = title + ' 2';
    }
    return copyTitle;
  }

  /**
   * @description Given a calcId, returns the data that would be
   * used on a chart, but filled with '-' for missing data when
   * compared to the other calculations
   * @param calcId
   */
  public getFilledCalcData(calcId: string): ICalculationData | undefined {
    const calc = this.getCalculation(calcId);
    if (!calc) return;
    const calcData = calc.getActiveData();
    if (!calcData) return;
    if (calcData.is_category_x_axis) return calcData;

    // For right now, we only need to add filler data
    // if the calc data is using the x and y arrays.
    if (!calcData.x || !calcData.x.length || !calcData.y || !calcData.y.length)
      return calcData;
    const x = [...calcData.x]; // Create a copy to avoid modifying the original
    let y = calcData.y.map((row) => ({ ...row, values: [...row.values] })); // Deep copy of y array

    const usingLogScale = this.config.yAxisLogScale;
    if (usingLogScale) {
      // If we're using log scale, then we'll fill
      // data points that are 0 with '-'
      y.forEach((row, i) => {
        y[i].values = row.values.map((yVal) => {
          if (yVal === 0) return null;
          return yVal;
        });
      });
    }

    this.calcArray.forEach((c) => {
      if (c.id === calcId) return;
      const otherCalcData = c.getActiveData();
      if (!otherCalcData) return;
      const otherX = otherCalcData.x;
      const otherY = otherCalcData.y;
      if (!otherX || !otherY) return;

      const missingEntries = otherX
        .map((xVal, index) => ({ value: xVal, index }))
        .filter(({ value }) => !x.includes(value));

      // Sort missing entries by their original index
      missingEntries.sort((a, b) => a.index - b.index);

      // Insert missing entries in the x array and y array at the correct positions
      missingEntries.forEach(({ value, index }) => {
        const insertIndex = x.findIndex((xVal) => otherX.indexOf(xVal) > index);
        const correctIndex = insertIndex === -1 ? x.length : insertIndex;
        x.splice(correctIndex, 0, value);
        y.splice(correctIndex, 0, { values: [usingLogScale ? null : 0] });
      });
    });

    calcData.x = x;
    calcData.y = y;
    return calcData;
  }
}
