<div class="container-thing">
  <div class="backdrop-overlay" (click)="CloseDialog()"></div>
  <div class="modal-container">
    <nb-card class="modal-sub-container">
      <nb-card-header class="modal-header">
        <!-- Top bar -->
        <div class="top-bar">
          <div class="col">
            <h3>{{ modalTitle }}</h3>
          </div>
          <div class="top-bar-controls">
            <div
              class="toggle"
              *ngIf="selectedData && selectedData.isSplitStack !== undefined"
            >
              <nb-toggle
                labelPosition="start"
                [checked]="selectedData.isSplitStack"
                (checkedChange)="toggleSegregation()"
                >{{ 'Split table' }}</nb-toggle
              >
            </div>
            <button
              nbButton
              status="basic"
              ghost
              (click)="refreshData()"
              [disabled]="hasLatestData || (loadingFullModal$ | async)"
              [nbSpinner]="loadingFullModal$ | async"
            >
              <nb-icon icon="refresh-outline"></nb-icon>
            </button>
            <button
              *ngIf="!noData && getSelectedDataLength() === 500"
              class="export-button"
              nbButton
              [nbSpinner]="loadingFullModal$ | async"
              [status]="fullModalButtonStatus"
              [disabled]="loadingFullModal$ | async"
              ghost
              (click)="getCsvData()"
            >
              <nb-icon icon="cloud-download-outline"></nb-icon> load all
            </button>
            <button
              *ngIf="!noData"
              class="export-button"
              nbButton
              [nbSpinner]="isLoading$ | async"
              status="basic"
              ghost
              (click)="exportTable()"
            >
              <nb-icon icon="cloud-download-outline"></nb-icon> Export
            </button>
            <resplendent-close-button
              (click)="CloseDialog()"
            ></resplendent-close-button>
          </div>
        </div>
      </nb-card-header>
      <nb-card-body>
        <div *ngIf="!noData; else noDataDisplay" class="sidebar-container">
          <div class="modal-content">
            <!-- Sidebar -->
            <div class="sidebar" *ngIf="(tableData$ | async).length > 1">
              <ng-container *ngFor="let data of tableData$ | async">
                <label
                  *ngIf="data.isFirstCategory"
                  class="label category-title"
                  >{{ calcNames[data.calcId] }}</label
                >
                <div
                  class="sidebar-item {{
                    data.isSplitStack ? 'stackedIndent' : ''
                  }}"
                  [ngClass]="{
                    'active-sidebar-item':
                      data?.subtitle === selectedData?.subtitle &&
                      data?.calcId === selectedData?.calcId
                  }"
                  (click)="selectTab(data)"
                >
                  <div
                    class="item-color"
                    [ngStyle]="{ backgroundColor: data.color }"
                  ></div>
                  <div class="item-title">
                    <h6
                      class="three-dots-overflow-boi"
                      [nbTooltip]="data?.subtitle"
                      [nbTooltipTrigger]="
                        $any(data?.subtitle?.length > 21 ? 'hint' : 'noop')
                      "
                    >
                      <ng-container
                        *ngIf="
                          data?.xAxisDataType === 'datetime' &&
                            validDate(data?.subtitle);
                          else noDate
                        "
                      >
                        <!-- A minute -->
                        <ng-container
                          *ngIf="widget.config.timeIncrement.value === 'minute'"
                        >
                          {{ sidebarTitle(data.subtitle) | date : 'medium' }}
                        </ng-container>
                        <!-- An hour -->
                        <ng-container
                          *ngIf="widget.config.timeIncrement.value === 'hour'"
                        >
                          {{ sidebarTitle(data.subtitle) | date : 'medium' }}
                        </ng-container>
                        <!-- A day -->
                        <ng-container
                          *ngIf="widget.config.timeIncrement.value === 'day'"
                        >
                          {{
                            sidebarTitle(data.subtitle) | date : 'mediumDate'
                          }}
                        </ng-container>
                        <!-- A week -->
                        <ng-container
                          *ngIf="widget.config.timeIncrement.value === 'week'"
                        >
                          {{
                            sidebarTitle(data.subtitle) | date : 'mediumDate'
                          }}
                        </ng-container>
                        <!-- A month -->
                        <ng-container
                          *ngIf="widget.config.timeIncrement.value === 'month'"
                        >
                          {{ sidebarTitle(data.subtitle) | date : 'MMM y' }}
                        </ng-container>
                        <!-- A quarter -->
                        <ng-container
                          *ngIf="
                            widget.config.timeIncrement.value === 'quarter'
                          "
                        >
                          {{ sidebarTitle(data.subtitle) | date : 'MMM y' }}
                        </ng-container>
                        <!-- A year -->
                        <ng-container
                          *ngIf="widget.config.timeIncrement.value === 'year'"
                        >
                          {{ sidebarTitle(data.subtitle) | date : 'y' }}
                        </ng-container>
                      </ng-container>
                      <ng-template #noDate>
                        {{ data?.subtitle }}
                      </ng-template>
                    </h6>
                  </div>
                </div>
              </ng-container>
            </div>
            <!-- Table -->
            <div
              class="table-container"
              [ngClass]="{
                'table-container-with-sidebar': (tableData$ | async).length > 1,
                'table-container-without-sidebar':
                  (tableData$ | async).length <= 1
              }"
            >
              <div
                *ngIf="!noData && !!selectedData; else noDataDisplay"
                style="height: 100%"
              >
                <ag-grid-angular
                  style="width: 100%; height: 100%"
                  class="ag-theme-alpine"
                  [rowData]="selectedData.data"
                  [columnDefs]="selectedData.agColumnDefs"
                  (gridReady)="onGridReady($event)"
                  (rowDataChanged)="!!gridApi && gridApi.autoSizeAllColumns()"
                  [gridOptions]="gridOptions"
                  [suppressFieldDotNotation]="true"
                  [enableCellTextSelection]="true"
                  (columnMoved)="onColumnMoved()"
                >
                </ag-grid-angular>
                <!-- <resplendent-table [tableData]="selectedData" [exportTable$]="exportTable$"></resplendent-table> -->
              </div>
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
</div>

<ng-template #noDataDisplay>
  <h1 class="title-no-data">No Data</h1>
  <div class="sub-no-data">No data available for this widget.</div>
</ng-template>
