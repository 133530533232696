<div class="mainCard" *ngIf="Filter !== undefined">
  <div class="splitRow">
    <div class="addButtons">
      <nb-toggle
        class="toggle"
        [(checked)]="Filter.useGrouping"
        (checkedChange)="checkForChanges()"
        *ngIf="Filter.useGrouping || showGrouping"
        >Use Grouping</nb-toggle
      >
      <nb-toggle
        class="toggle"
        [(checked)]="Filter.onlyBusinessDays"
        (checkedChange)="checkForChanges()"
        >When subtracting dates only count business days.
      </nb-toggle>
    </div>
    <div class="itemAndLabel m5" *ngIf="Filter.useGrouping && showGrouping">
      <label class="label">Select a column to group by.</label>
      <nb-select
        [(selected)]="Filter.grouping.groupingKey"
        (selectedChange)="checkForChanges()"
        class="simple-select"
      >
        <nb-option *ngFor="let col of columns" [value]="col.name">
          {{ !!col.alias ? col.alias : col.name }}
        </nb-option>
      </nb-select>
    </div>
  </div>
  <resplendent-row-filter-gui-v2
    *ngIf="Filter.useGrouping && showGrouping"
    [columns]="columns"
    [filterObject]="Filter.grouping.filter.pandas_filter"
    (filterChanged)="checkForChanges()"
  ></resplendent-row-filter-gui-v2>

  <div class="splitRow m5">
    <div class="addButtons">
      <button
        nbButton
        ghost
        size="small"
        status="primary"
        (click)="add_object('column')"
      >
        add column
      </button>
      <button
        nbButton
        ghost
        size="small"
        status="success"
        (click)="add_object('value')"
      >
        add value
      </button>
      <button
        nbButton
        ghost
        size="small"
        status="warning"
        (click)="add_object('type')"
      >
        change type
      </button>
    </div>
  </div>
  <div
    [class]="(moveMode ? 'columnMode' : 'rowMode') + ' itemList'"
    *ngIf="Filter !== undefined"
  >
    <ng-container *ngFor="let item of items; let i = index">
      <button
        nbButton
        ghost
        size="small"
        class="operatorButton"
        *ngIf="item.index !== 0 && item.type !== 'type'"
        [nbPopover]="operatorChoices"
      >
        {{ item.logicOperator }}
      </button>
      <ng-template #operatorChoices>
        <button
          nbButton
          ghost
          size="small"
          *ngFor="let opt of operators[item.index - 1]"
          (click)="
            item.logicOperator = $any(opt); validateFilter(); closePopovers()
          "
        >
          {{ opt }}
        </button>
      </ng-template>
      <div class="typeLabel" *ngIf="item.index !== 0 && item.type === 'type'">
        {{ typeLabels[SpotTypes[item.index - 1]] }} =>
      </div>
      <div class="addButtons" (mouseup)="switchItems(item)">
        <ng-container
          *ngIf="
            Filter.leftParentheses[item.index] !== undefined &&
            Filter.leftParentheses[item.index].length > 0
          "
        >
          <div class="parenthesesContainer">
            <div
              class="parentheses"
              *ngFor="let uuid of Filter.leftParentheses[item.index]"
              [nbPopover]="parenthesesMenu"
            >
              (
              <ng-template #parenthesesMenu>
                <div class="addButtons">
                  <button
                    nbButton
                    ghost
                    size="small"
                    (click)="moveParentheses(item, 'left', 'left_parentheses')"
                  >
                    <nb-icon icon="chevron-left-outline"></nb-icon>
                  </button>
                  <button
                    nbButton
                    ghost
                    size="small"
                    (click)="deleteParentheses(uuid)"
                  >
                    <nb-icon icon="trash-outline"></nb-icon>
                  </button>
                  <button
                    nbButton
                    ghost
                    size="small"
                    (click)="moveParentheses(item, 'right', 'left_parentheses')"
                  >
                    <nb-icon icon="chevron-right-outline"></nb-icon>
                  </button>
                </div>
              </ng-template>
            </div>
          </div>
        </ng-container>
        <div cdkDropList cdkDropListOrientation="horizontal">
          <div #thingy [ngSwitch]="item.type" cdkDrag>
            <div
              *cdkDragPlaceholder
              class="placeholder {{ item.type }}Color"
              [style.width.px]="thingy.offsetWidth - 10"
              [style.height.px]="thingy.offsetHeight - 10"
            ></div>
            <ng-container *ngSwitchCase="'column'">
              <ng-container *resplendentNgVar="colItem(item) as item">
                <div
                  class="item {{
                    (item.valid === 'red' ? 'invalid' : 'valid') +
                      'Color ' +
                      item.type
                  }}Color"
                >
                  <div class="itemAndLabel">
                    <label class="label addButtons"
                      ><span class="{{ item.type }}FontColor">Column</span>
                      <span class="itemTypeLabel"
                        >Type: {{ typeLabels[colDtypes[item.column]] }}</span
                      >
                    </label>
                    <resplendent-autocomplete
                      [value]="item.column"
                      (valueChanges)="item.column = $event; validateFilter()"
                      [possibleValues]="colNames"
                      [aliasMap]="colAliases"
                      [size]="'small'"
                      [shape]="'semi-round'"
                    ></resplendent-autocomplete>
                  </div>
                  <div class="itemAndLabel ml5" *ngIf="!item.aggregate">
                    <label class="label">Row Offset</label>
                    <input
                      type="number"
                      nbInput
                      fieldSize="small"
                      shape="semi-round"
                      [value]="item.offset"
                      class="colInput2"
                      (input)="handleOffsetChange($event, item)"
                    />
                  </div>
                  <div class="itemAndLabel ml5" *ngIf="item.aggregate">
                    <label class="label">Aggregate Type</label>
                    <nb-select
                      [(selected)]="item.aggregateOperation"
                      size="small"
                      shape="semi-round"
                      class="colInput2"
                      (selectedChange)="validateFilter()"
                    >
                      <nb-option value="sum">Sum</nb-option>
                      <nb-option value="mean">Average</nb-option>
                      <nb-option value="count">Count</nb-option>
                      <nb-option value="unique_count">Unique Count</nb-option>
                      <nb-option value="median">Median</nb-option>
                      <nb-option value="max">Max</nb-option>
                      <nb-option value="min">Min</nb-option>
                      <nb-option value="mode">Mode</nb-option>
                      <nb-option value="range">Range</nb-option>
                    </nb-select>
                  </div>
                  <button
                    nbButton
                    ghost
                    size="small"
                    [nbPopover]="columnSettings"
                  >
                    <nb-icon icon="settings-outline"></nb-icon>
                  </button>
                  <div
                    class="dragButton"
                    cdkDragHandle
                    (mousedown)="dragStart(item)"
                  >
                    <nb-icon icon="move-outline"></nb-icon>
                  </div>
                  <ng-template #columnSettings>
                    <div style="width: min-content">
                      <button
                        nbButton
                        ghost
                        size="small"
                        fullWidth
                        (click)="deleteItem(item.index)"
                      >
                        <nb-icon icon="trash-outline"></nb-icon>
                      </button>
                      <button
                        nbButton
                        ghost
                        size="small"
                        fullWidth
                        (click)="addParentheses(item.index)"
                      >
                        add parentheses
                      </button>
                      <button
                        nbButton
                        ghost
                        size="small"
                        [status]="item.aggregateFilter ? 'primary' : 'basic'"
                        fullWidth
                        (click)="openFilterDialog(item.index)"
                        *ngIf="item.aggregate"
                      >
                        filter
                      </button>
                      <div class="addButtons" *ngIf="!aggregateMode">
                        <button
                          nbButton
                          ghost
                          size="small"
                          [status]="!item.aggregate ? 'warning' : 'basic'"
                          (click)="item.aggregate = false; validateFilter()"
                        >
                          regular
                        </button>
                        <button
                          nbButton
                          ghost
                          size="small"
                          [status]="item.aggregate ? 'warning' : 'basic'"
                          (click)="item.aggregate = true; validateFilter()"
                        >
                          aggregate
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'type'">
              <ng-container *resplendentNgVar="typeItem(item) as item">
                <div
                  class="item {{
                    (item.valid === 'red' ? 'invalid' : 'valid') +
                      'Color ' +
                      item.type
                  }}Color"
                >
                  <div class="itemAndLabel">
                    <label class="label addButtons"
                      ><span class="{{ item.type }}FontColor">Change Type</span>
                    </label>
                    <nb-select
                      [(selected)]="item.convertToType"
                      size="small"
                      shape="semi-round"
                      class="typeSelect"
                      (selectedChange)="validateFilter()"
                    >
                      <nb-option
                        *ngFor="let type of operators[item.index - 1]"
                        [value]="type"
                        >{{ typeLabels[type] }}</nb-option
                      >
                    </nb-select>
                  </div>
                  <div class="itemAndLabel">
                    <label class="label">Units</label>
                    <nb-select
                      size="small"
                      shape="semi-round"
                      class="unitSelect"
                      [(selected)]="item.units"
                      (selectedChange)="validateFilter()"
                      *ngIf="
                        dtype_conversions[SpotTypes[item.index - 1]] !==
                          undefined &&
                          dtype_conversions[SpotTypes[item.index - 1]][
                            item.convertToType
                          ] !== null;
                        else noUnits
                      "
                    >
                      <nb-option
                        *ngFor="
                          let unit of dtype_conversions[
                            SpotTypes[item.index - 1]
                          ][item.convertToType]
                        "
                        [value]="unit"
                      >
                        {{ unit }}
                      </nb-option>
                    </nb-select>
                  </div>

                  <ng-template #noUnits>
                    <nb-select
                      size="small"
                      shape="semi-round"
                      disabled
                      class="unitSelect"
                      selected="none"
                    >
                      <nb-option value="none">No Units</nb-option>
                    </nb-select>
                  </ng-template>
                  <div nbPopover="Select Timezone" nbPopoverTrigger="hint">
                    <button
                      nbButton
                      size="small"
                      ghost
                      *ngIf="
                        item.convertToType === 'datetime' ||
                        (item.convertToType === 'date' &&
                          (SpotTypes[item.index - 1] === 'string' ||
                            SpotTypes[item.index - 1] === 'string' ||
                            SpotTypes[item.index - 1] === 'float'))
                      "
                      [nbPopover]="timezoneSelect"
                    >
                      <nb-icon icon="clock-outline"></nb-icon>
                    </button>
                  </div>
                  <button
                    nbButton
                    ghost
                    size="small"
                    [nbPopover]="typeSettings"
                  >
                    <nb-icon icon="settings-outline"></nb-icon>
                  </button>
                  <ng-template #typeSettings>
                    <button
                      nbButton
                      ghost
                      size="small"
                      fullWidth
                      (click)="deleteItem(item.index)"
                    >
                      <nb-icon icon="trash-outline"></nb-icon>
                    </button>
                    <button
                      nbButton
                      ghost
                      size="small"
                      fullWidth
                      (click)="addParentheses(item.index)"
                    >
                      add parentheses
                    </button>
                  </ng-template>
                  <div
                    class="dragButton"
                    cdkDragHandle
                    (mousedown)="dragStart(item)"
                  >
                    <nb-icon icon="move-outline"></nb-icon>
                  </div>
                  <ng-template #timezoneSelect>
                    <div class="itemAndLabel m5">
                      <label class="label">Timezone</label>
                      <resplendent-autocomplete
                        class="timezoneSelect"
                        size="small"
                        shape="semi-round"
                        [possibleValues]="timezones"
                        (valueChanges)="item.timezone = $event"
                        [value]="item.timezone"
                      ></resplendent-autocomplete>
                    </div>
                  </ng-template>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'value'">
              <ng-container *resplendentNgVar="valueItem(item) as item">
                <div
                  class="item {{
                    (item.valid === 'red' ? 'invalid' : 'valid') +
                      'Color ' +
                      item.type
                  }}Color"
                >
                  <div class="itemAndLabel">
                    <label class="label addButtons"
                      ><span class="{{ item.type }}FontColor">Value</span>
                      <span class="itemTypeLabel"
                        >Type:
                        {{
                          item.useAdvanced
                            ? typeLabels[item.advancedValueType]
                            : typeLabels[item.dtype]
                        }}</span
                      >
                    </label>
                    <input
                      type="text"
                      nbInput
                      fieldSize="small"
                      shape="semi-round"
                      *ngIf="!item.useAdvanced"
                      [value]="item.value"
                      (input)="
                        item.value = $any($event.target).value; validateFilter()
                      "
                    />
                    <div
                      *ngIf="item.useAdvanced"
                      (click)="openAdvancedValueDialog(item)"
                      class="wrapperDiv"
                    >
                      <input
                        type="text"
                        class="advancedInput"
                        nbInput
                        fieldSize="small"
                        shape="semi-round"
                        disabled
                        [value]="item.valuePreview"
                      />
                      <div class="overlayDiv"></div>
                    </div>
                  </div>
                  <button
                    nbButton
                    ghost
                    size="small"
                    [nbPopover]="valueSettings"
                  >
                    <nb-icon icon="settings-outline"></nb-icon>
                  </button>

                  <div
                    class="dragButton"
                    cdkDragHandle
                    (mousedown)="dragStart(item)"
                  >
                    <nb-icon icon="move-outline"></nb-icon>
                  </div>
                  <ng-template #valueSettings>
                    <button
                      nbButton
                      ghost
                      size="small"
                      fullWidth
                      (click)="deleteItem(item.index)"
                    >
                      <nb-icon icon="trash-outline"></nb-icon>
                    </button>
                    <button
                      nbButton
                      ghost
                      size="small"
                      fullWidth
                      (click)="addParentheses(item.index)"
                    >
                      add parentheses
                    </button>
                    <div class="addButtons" *ngIf="!item.useAdvanced">
                      <button
                        nbButton
                        ghost
                        size="small"
                        *ngFor="let dtype of dtypes"
                        [status]="
                          item.dtype === dtype.value ? 'warning' : 'basic'
                        "
                        (click)="item.dtype = dtype.value; validateFilter()"
                      >
                        {{ dtype.label }}
                      </button>
                    </div>
                    <div class="addButtons">
                      <button
                        nbButton
                        ghost
                        size="small"
                        [status]="!item.useAdvanced ? 'primary' : 'basic'"
                        (click)="item.useAdvanced = false; validateFilter()"
                      >
                        Basic
                      </button>
                      <button
                        nbButton
                        ghost
                        size="small"
                        [status]="item.useAdvanced ? 'primary' : 'basic'"
                        (click)="
                          item.useAdvanced = true;
                          validateFilter();
                          UpdateAdvancedValuePreviews()
                        "
                      >
                        Advanced Value
                      </button>
                    </div>
                  </ng-template>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <ng-container
          *ngIf="
            Filter.rightParentheses[item.index] !== undefined &&
            Filter.rightParentheses[item.index].length > 0
          "
        >
          <div class="parenthesesContainer" [nbPopover]="">
            <div
              class="parentheses"
              *ngFor="let uuid of Filter.rightParentheses[item.index]"
              [nbPopover]="parenthesesMenu"
            >
              )
              <ng-template #parenthesesMenu>
                <div class="addButtons">
                  <button
                    nbButton
                    ghost
                    size="small"
                    (click)="moveParentheses(item, 'left', 'right_parentheses')"
                  >
                    <nb-icon icon="chevron-left-outline"></nb-icon>
                  </button>
                  <button
                    nbButton
                    ghost
                    size="small"
                    (click)="deleteParentheses(uuid)"
                  >
                    <nb-icon icon="trash-outline"></nb-icon>
                  </button>
                  <button
                    nbButton
                    ghost
                    size="small"
                    (click)="
                      moveParentheses(item, 'right', 'right_parentheses')
                    "
                  >
                    <nb-icon icon="chevron-right-outline"></nb-icon>
                  </button>
                </div>
              </ng-template>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
