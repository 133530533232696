import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BaseComponent } from '@base-component';
import { getContrastColor } from '@helpers';
import {
  IThresholdsV2,
  IWidgetClickTargetV2,
  SortingType,
  WidgetType,
} from '@interfaces';
import { NbThemeService } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { IAppStateModel } from '@root/state/app.model';
import { WidgetStateObject } from '@root/state/state-model-objects/widget.state-model';
import * as echarts from 'echarts';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  filter,
  map,
  startWith,
  takeUntil,
  tap,
  throttleTime,
} from 'rxjs';
import {
  abbreviateNumber,
  barChartDataOptions,
  buildDeliciousChart,
  buildFunnelChart,
  buildGaugeChart,
  buildLabelFormatter,
  buildStackedTotalLabelFormatter,
  buildTooltipValueFormatter,
  buildVisualMapLabelFormatter,
  comparisonLineChartOptions,
  formatDate,
  getDateFormat,
  getXAxisInterval,
  lineChartDataOptions,
  themeToContrast,
} from './chart-v2.helpers';
import { TimeDelta } from '@root/custom_scripts/TimeDeltaStuff';

type DataStatuses =
  | 'data'
  | 'loading'
  | 'noData'
  | 'noIFrameLink'
  | 'noImageLink'
  | 'disabled'
  | 'emptyData'
  | 'noEditorData'
  | 'brokenWidget';

interface IVisualMap {
  show: boolean;
  pieces: any[];
  top?: number;
  right?: number;
  bottom?: number;
  orient?: 'horizontal' | 'vertical';
  textStyle?: {
    color: string;
  };
  formatter?: string | ((value1: string, value2: number) => string);
}

interface INumberWidgetData {
  text: string;
  color: string;
  fontSize: number;
}

@Component({
  selector: 'resplendent-chart-v2',
  templateUrl: './chart-v2.component.html',
  styleUrls: ['./chart-v2.component.scss'],
})
export class ChartV2Component
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() widgetId: string;
  @Input() isInEditor: boolean = false;
  @Output() emitWidgetClick = new EventEmitter<IWidgetClickTargetV2>();

  chartStarterOptions: echarts.EChartsOption = {
    textStyle: {
      fontFamily: 'muller',
    },
  };

  chartOptionsSource$ = new BehaviorSubject<echarts.EChartsOption>(null);
  chartOptions$ = this.chartOptionsSource$.asObservable();
  resizeObserver: ResizeObserver;
  echartsInstance: echarts.ECharts;

  widget$ = this.store.select(
    (state) => (state.app as IAppStateModel).appState.widgets[this.widgetId],
  );
  maxFontSize$ = this.store
    .select(
      (state) =>
        (state.app as IAppStateModel).appState?.currentDashboard?.maxFontSize,
    )
    .pipe(filter((x) => !!x));

  mainViewDisplay$: Observable<DataStatuses> = this.widget$.pipe(
    filter((widget) => !!widget && !widget.isLoading),
    map((widget) => {
      if (widget.widgetType === 'iFrame') {
        if (widget.iFrameUrl) {
          return 'data';
        } else return 'noIFrameLink';
      }
      if (widget.widgetType === 'staticString') return 'data';
      if (widget.widgetType === 'image') {
        if (widget.imageUrl) {
          return 'data';
        } else return 'noImageLink';
      }
      if (!widget.featureItemEnabled) return 'disabled';
      if (widget.hasBrokenData && !this.isInEditor) return 'brokenWidget';
      if (!widget.hasSomeData && !this.isInEditor) return 'noData';
      if (!widget.hasSomeData && this.isInEditor) return 'noEditorData';
      return 'data';
    }),
  );

  showChart = true;

  // Used to decided when to render the chart
  renderChartSource$ = new BehaviorSubject<boolean>(false);
  renderChart$ = this.renderChartSource$.asObservable();

  // If not the first load, don't animate the chart
  firstLoad = true;

  themeToSplitLineColor = {
    dark: '#2e3a59', // color-basic-700
    cosmic: '#2e3a59', // color-basic-700
    default: '#e4e9f2', // color-basic-400
  };

  themeToAxisTextColor = {
    dark: '#8f9bb3', // color-basic-600
    cosmic: '#8f9bb3', // color-basic-600
    default: '#2e3a59', // color-basic-700
  };
  themeToAxisLineColor = {
    dark: '#2e3a59', // color-basic-700
    cosmic: '#2e3a59', // color-basic-700
    default: '#e4e9f2', // color-basic-400
  };

  orderedDaysOfTheWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  private autoResizeStringSource$ = new BehaviorSubject<INumberWidgetData>(
    null,
  );
  public autoResizeString$ = this.autoResizeStringSource$.asObservable();

  noDataWidgetType = false;

  constructor(
    private store: Store,
    private ngZone: NgZone,
    private theme: NbThemeService,
  ) {
    super();
  }

  @ViewChild('componentContainer') componentContainer: ElementRef;

  ngOnInit() {
    const widget = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.widgets[this.widgetId],
    );

    if (
      widget.widgetType === 'iFrame' ||
      widget.widgetType === 'staticString' ||
      widget.widgetType === 'image'
    ) {
      this.showChart = false;
      this.noDataWidgetType = true;
    } else if (
      widget.widgetType === 'number' ||
      widget.widgetType === 'dynamicString'
    ) {
      this.showChart = false;
    } else {
      this.showChart = true;
    }

    const renderTrigger$ = combineLatest([
      this.widget$.pipe(startWith(widget)),
      this.maxFontSize$.pipe(startWith(null)),
      this.theme.onThemeChange().pipe(startWith(null)),
      this.renderChart$.pipe(startWith(false)),
    ]);
    renderTrigger$
      .pipe(
        takeUntil(this.isDestroyed$),
        filter(([widget, _]) => !!widget && !widget.isLoading),
        // Make sure renderChart$ is true
        filter(([_, __, ___, renderChart]) => renderChart),
        // ? On the first load, we don't need to throttle the rendering
        // as much, but on subsequent loads, we can throttle it more
        // to make sure the chart animation finishes
        throttleTime(this.firstLoad ? 300 : 700, undefined, {
          leading: false,
          trailing: true,
        }),
        map(([widget, _]) => widget),
        filter(
          (widget) =>
            widget.hasSomeData || widget.widgetType === 'staticString',
        ),
        tap((widget) => {
          this.renderWidget(widget);
        }),
      )
      .subscribe();

    if (!this.showChart) {
      // Since we're not showing the chart, we can just render the widget
      this.renderChartSource$.next(true);
    }
  }

  private widgetTypeUsesChart(widgetType: WidgetType): boolean {
    switch (widgetType) {
      case 'bar':
      case 'line':
      case 'pie':
      case 'doughnut':
      case 'gauge':
      case 'funnel':
        return true;
      default:
        return false;
    }
  }

  private chartTypeCanUseThreshold(chartType: WidgetType): boolean {
    switch (chartType) {
      case 'bar':
      case 'line':
        return true;
      default:
        return false;
    }
  }

  private async buildChartOptions(
    widget: WidgetStateObject,
  ): Promise<echarts.EChartsOption> {
    const chartOption: echarts.EChartsOption = {
      textStyle: {
        fontFamily: 'muller',
      },
    };

    const widgetConfig = widget.getEzConfigMap();

    if (!widgetConfig) {
      return chartOption;
    }

    const chartType = widget.widgetType;

    const widgetUsesChart = this.widgetTypeUsesChart(chartType);

    const stackMethod = widget.config.grouping.value;
    const roundTo = widget.config.rounding;
    const horizontalChart = widget.config.horizontalChart;

    if (!widgetUsesChart) {
      return chartOption;
    }

    const globalLabelColors = this.store.selectSnapshot(
      (state) =>
        (state.app as IAppStateModel).appState.currentDashboard
          ?.customLabelsAndColors,
    );

    const isDelicious = chartType === 'pie' || chartType === 'doughnut';
    const prefix = widgetConfig.dollarPrefix ? '$' : '';
    const suffix = widgetConfig.percentSuffix ? '%' : '';

    let yAxisType = widget.config.yAxisLogScale ? 'log' : 'value';
    // If the chart is horizontal, the x axis is actually the y axis
    const xAxisType = horizontalChart ? yAxisType : widget.xAxisType;
    // If the chart is horizontal, the y axis is actually the x axis
    yAxisType = horizontalChart ? widget.xAxisType : yAxisType;

    let xAxisSettings = {
      show: widgetConfig.xAxis,
      type: xAxisType,
      name: widget.config.xLabel,
      nameLocation: 'center',
      nameTextStyle: {
        color: this.themeToAxisTextColor[this.theme.currentTheme],
        fontSize: 14,
      },
      nameGap: 30,
      axisLabel: {
        color: this.themeToAxisTextColor[this.theme.currentTheme],
        overflow: 'truncate',
        width: 200,
        hideOverlap: true,
        rotate: widget.config.xAxisLabelRotation,
      },
      axisLine: {
        lineStyle: {
          color: this.themeToAxisLineColor[this.theme.currentTheme],
        },
      },
    } as any;

    const timeResolution = widget.config.timeIncrement?.value;
    if (widget.xAxisType === 'time') {
      if (widget.widgetType === 'line') {
        (xAxisSettings as any).axisLabel.showMinLabel = true;
        (xAxisSettings as any).axisLabel.showMaxLabel = true;
      }
      // Format the time axis
      if (timeResolution) {
        (xAxisSettings as any).maxInterval = getXAxisInterval(
          timeResolution,
          1,
        );
        (xAxisSettings as any).minInterval = getXAxisInterval(
          timeResolution,
          1,
        );
        xAxisSettings.axisLabel['formatter'] = (value) => {
          const formattedValue = formatDate(
            value,
            getDateFormat(timeResolution),
          );
          return formattedValue;
        };
      }
    }

    if (xAxisSettings.type === 'category') {
      (xAxisSettings.axisLabel as any).interval = 0;
      (xAxisSettings.axisLabel as any).ellipsis = '...';
    }

    let yAxisSettings = {
      show: widgetConfig.yAxis,
      type: yAxisType,
      max: widget.config.yMax.value || undefined,
      min: widget.config.yMin.value || undefined,
      name: widget.config.yLabel,
      nameLocation: 'center',
      nameTextStyle: {
        color: this.themeToAxisTextColor[this.theme.currentTheme],
        fontSize: 14,
      },
      nameGap: 30,
      axisLine: {
        lineStyle: {
          color: this.themeToSplitLineColor[this.theme.currentTheme],
        },
      },
      axisLabel: {
        color: this.themeToAxisTextColor[this.theme.currentTheme],
        hideOverlap: true,
        showMaxLabel: true,
        // Show min label for horizontal charts to ensure the first label is visible
        showMinLabel: horizontalChart,
      },
      splitLine: {
        lineStyle: {
          color: this.themeToSplitLineColor[this.theme.currentTheme],
          type: 'dashed',
        },
      },
    } as any;
    if (yAxisSettings.type === 'log') {
      yAxisSettings.logBase = widget.config.logScaleBase;
      // The min value can't be 0 when using a log scale
      yAxisSettings.min = undefined;
    } else if (yAxisSettings.type === 'value') {
      yAxisSettings.axisLabel['formatter'] = buildLabelFormatter(
        prefix,
        suffix,
        widget.config.abbreviateNumbers.toggled,
        1,
        undefined,
        widget.calcArray[0]?.getActiveData()?.type,
        'bar',
      );
    }

    chartOption.xAxis = [xAxisSettings];
    chartOption.yAxis = [yAxisSettings];

    // If comparePoints is enabled, we need to add a second y axis
    if (widget.config.comparePoints) {
      chartOption.yAxis.push({
        show: false,
        type: 'value',
      });
    }

    chartOption.legend = this.buildLegend(widget);

    chartOption.grid = this.buildGrid(widget);
    if (widget.config.colorType.value === 'thresholds') {
      // If the chart is using thresholds, we need to add some padding
      // to the right of the chart so the labels don't get cut off
      // chartOption.grid.right = 50;
      // chartOption.grid.bottom = 50;

      // We also need to build the visualMap
      if (!widget.config.useCalculationThresholds) {
        chartOption.visualMap = this.buildVisualMap(
          widget.thresholds,
          prefix,
          suffix,
          widget.config.abbreviateNumbers.toggled,
          roundTo,
        );
      }
    }

    if (isDelicious) {
      chartOption.series = buildDeliciousChart({
        widget,
        chartType,
        roundTo,
        prefix,
        suffix,
        globalLabelColors,
        currentTheme: this.theme.currentTheme,
      });
    } else if (chartType === 'gauge') {
      chartOption.series = buildGaugeChart({
        widget,
        roundTo,
        prefix,
        suffix,
        globalLabelColors,
        currentTheme: this.theme.currentTheme,
      });
    } else if (chartType === 'funnel') {
      chartOption.series = buildFunnelChart({
        widget,
        roundTo,
        prefix,
        suffix,
        globalLabelColors,
        currentTheme: this.theme.currentTheme,
      });
    } else {
      chartOption.series = widget.calcArray
        .filter((calc) => calc.hasData)
        .flatMap((calc) => {
          let data: any[][] = [];
          let comparisonData: any[][] = [];
          const calcData = widget.getFilledCalcData(calc.id);
          const useCalcNameAsXAxis = !calc.xAxis;
          const xGroupings = calcData.x_groupings;
          calcData?.y?.map((row, rowIndex) => {
            const x = calcData.x[rowIndex];

            row.values?.map((y, dataIndex) => {
              let label: string;
              if (xGroupings) {
                label = xGroupings[dataIndex];
              } else if (calcData.is_category_x_axis) {
                label = x;
              } else {
                label = calc.name;
              }

              // We only want to set the color if the colorType isn't thresholds
              const color =
                widget.config.colorType.value === 'thresholds'
                  ? undefined
                  : globalLabelColors?.[x] ||
                    globalLabelColors?.[label] ||
                    globalLabelColors?.[calc.name] ||
                    widget.getSingleColor(calc.id, label);

              let options;
              if (chartType === 'bar') {
                options = barChartDataOptions(
                  x,
                  y as number,
                  color,
                  widgetConfig.dataLabels,
                  calc.id,
                  label,
                  stackMethod,
                  roundTo,
                  prefix,
                  suffix,
                  widget.xAxisType,
                  timeResolution,
                  widget.config,
                  calcData?.y?.[rowIndex - 1]?.values?.[dataIndex] ?? null,
                  widget,
                );
              } else if (chartType === 'line') {
                const onLastDataPoint = rowIndex === calcData.y.length - 1;
                options = lineChartDataOptions(
                  x,
                  y as number,
                  color,
                  widgetConfig.dataLabels,
                  calc.id,
                  label,
                  stackMethod,
                  onLastDataPoint,
                  roundTo,
                  prefix,
                  suffix,
                  widget.xAxisType,
                  timeResolution,
                  widget.config,
                  calcData?.y?.[rowIndex - 1]?.values?.[dataIndex] ?? null,
                  widget,
                );
              }

              // Check if there is an array at index dataIndex
              if (data[dataIndex]) {
                // If there is an array at index dataIndex, push the options
                // to the array at index dataIndex
                data[dataIndex].push(options);
              } else {
                // If there is no array at index i, create a new array
                // and push the options to the new array
                data[dataIndex] = [options];
              }

              // If comparePoints is enabled, we need to build a second series
              // that compares the points
              if (
                widget.config.comparePoints &&
                !widget.config.comparisonOnlyShowInLabel
              ) {
                const comparisonOptions = comparisonLineChartOptions(
                  x,
                  y as number,
                  calcData?.y?.[rowIndex - 1]?.values?.[dataIndex] ?? null,
                  label,
                  widget.config,
                  widget.xAxisType,
                  timeResolution,
                  widget,
                );

                // Check if there is an array at index dataIndex
                if (comparisonData[dataIndex]) {
                  // If there is an array at index dataIndex, push the options
                  // to the array at index dataIndex
                  comparisonData[dataIndex].push(comparisonOptions);
                } else {
                  // If there is no array at index i, create a new array
                  // and push the options to the new array
                  comparisonData[dataIndex] = [comparisonOptions];
                }
              }
            });
          });

          data = this.sortData(data, widget.config.sorting.value);
          comparisonData = this.sortData(
            comparisonData,
            widget.config.sorting.value,
          );

          if (widget.config.valueLimit) {
            data.forEach((row, index) => {
              data[index] = row.slice(0, widget.config.valueLimit);
            });
          }
          const seriesConfig = {
            name: calc.name,
            type: chartType as any,
            zlevel: 1,
            colorBy: 'data',
            emphasis: {
              itemStyle: {
                shadowBlur: 8,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          };

          // Extra Line Options
          if (chartType === 'line') {
            seriesConfig['smooth'] = true;
            // seriesConfig['labelLayout'] = (params) => {
            //   return {
            //     dy: params.seriesIndex * 15,
            //   };
            // };
            seriesConfig['lineStyle'] = {
              width: 3,
            };
            seriesConfig['areaStyle'] = {
              opacity: widget.config.showArea ? 0.5 : 0,
            };
          }

          switch (widget.config.grouping.value) {
            case 'stackByLabel':
              seriesConfig['stack'] = 'total';
              break;
            case 'stackByCalculation':
              seriesConfig['stack'] = 'total';
              break;
            default:
              break;
          }

          // Extra Bar Options
          if (chartType === 'bar') {
            seriesConfig['barCategoryGap'] = `${widget.config.barGap}%`;

            if (useCalcNameAsXAxis) {
              seriesConfig['stack'] = 'useCalcNameAsXAxis';
            }
          }

          if (
            this.chartTypeCanUseThreshold(chartType) &&
            widget.config.colorType.value === 'thresholds' &&
            !widget.config.useCalculationThresholds
          ) {
            seriesConfig['markLine'] = this.buildMarkLines(
              widget.thresholds,
              prefix,
              suffix,
              widget.config.abbreviateNumbers.toggled,
              roundTo,
              !!widget.config.showThresholdMarkers,
              widget.calcArray[0]?.getActiveData()?.type,
            );
          }

          const series = data.map((row, index) => {
            let label: string;
            if (calcData.x_groupings) {
              label = calcData.x_groupings[index];
            } else {
              label = calc.name;
            }
            if (!label || label === 'null') {
              label = 'Blank';
            }
            const color =
              globalLabelColors?.[label] ||
              widget.getSingleColor(calc.id, label);

            return {
              ...seriesConfig,
              data: row,
              name: label,
              itemStyle: {
                color: color,
              },
            };
          }) as any[];
          // Sort the series by the label
          if (widget.config.groupBySorting === 'default') {
            series.sort((a, b) => a.name.localeCompare(b.name));
          } else if (widget.config.groupBySorting === 'reverse') {
            series.sort((a, b) => b.name.localeCompare(a.name));
          }

          const comparisonSeries = comparisonData.map((row, index) => {
            let label: string;
            if (calcData.x_groupings) {
              label = calcData.x_groupings[index];
            } else {
              label = calc.name;
            }
            if (!label || label === 'null') {
              label = 'Blank';
            }

            return {
              ...seriesConfig,
              type: 'line',
              data: row,
              name: `${label} Comparison`,
              itemStyle: {
                // color: this.themeToSplitLineColor[this.theme.currentTheme],
                color: 'rgba(112, 128, 144, 0.4)',
              },
              lineStyle: {
                type: 'dotted',
              },
              yAxisIndex: 1,
              tooltip: {
                show: false,
              },
            };
          }) as any[];

          return [...series, ...comparisonSeries];
        });

      // If the bar chart is stacked and the user wants to show the total
      // value of the stack, we need to add an additional series. The additional
      // series will only have 0 values, but will have a data label with a formatter
      // that calculates the total value of the stack.
      if (
        (widget.config.grouping.value === 'stackByLabel' ||
          widget.config.grouping.value === 'stackByCalculation') &&
        chartType === 'bar' &&
        widget.config.showStackTotal
      ) {
        const totalSeries = {
          name: '',
          type: 'bar',
          stack: 'total',
          data: (chartOption.series[0]?.data as any[]).map((item) => {
            return {
              value: [item.value[0], 0],
            };
          }),
          label: {
            show: true,
            position: 'top',
            formatter: buildStackedTotalLabelFormatter({
              widget: widget,
            }),
            fontWeight: 'bold',
            color: themeToContrast[this.theme.currentTheme],
          },
          itemStyle: {
            color: 'rgba(0,0,0,0)',
          },
        };
        chartOption.series.push(totalSeries as any);
      }
    }

    // Tooltip options
    chartOption.tooltip = {
      show: true,
      trigger: isDelicious || chartType === 'funnel' ? 'item' : 'axis',
      confine: true,
      enterable: true,
      formatter:
        chartType === 'bar' || chartType === 'line'
          ? buildTooltipValueFormatter({
              widget: widget,
              prefix,
              suffix,
            })
          : undefined,
    };

    chartOption.animation = this.firstLoad;
    chartOption.animationDuration = 300;
    chartOption.animationEasing = 'cubicInOut';
    return chartOption;
  }

  private async renderWidget(widget: WidgetStateObject) {
    if (this.showChart) {
      // If the chart hasn't been initialized yet, initialize it
      this.chartOptionsSource$.next(await this.buildChartOptions(widget));
      if (widget.widgetType === 'line' && widget.config.overlayCurrentNumber) {
        this.renderTextWidget(widget);
      }
      this.firstLoad = false;
    } else if (
      widget.widgetType === 'number' ||
      widget.widgetType === 'dynamicString' ||
      widget.widgetType === 'staticString'
    ) {
      setTimeout(() => {
        this.renderTextWidget(widget);
      }, 10);
    }
  }

  onChartInit(ec) {
    if (!this.echartsInstance) {
      this.echartsInstance = ec;
    }
    if (!this.renderChartSource$.value) {
      this.renderChartSource$.next(true);
    }
  }

  async resizeChart() {
    if (this.echartsInstance) {
      this.echartsInstance.resize();
    }
  }

  onChartClick(event: any) {
    const clickTarget: IWidgetClickTargetV2 = {
      widgetId: this.widgetId,
      calcTarget: {
        label: event.name,
        frontEndCalcId: event.data['calcId'],
      },
      xValue: event.data?.['originalX'] ?? (event.value[0] || null),
    };
    this.emitWidgetClick.emit(clickTarget);
  }

  numberClick() {
    const widget = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.widgets[this.widgetId],
    );
    if (!widget.calcArray?.[0]) {
      return;
    }
    const clickTarget: IWidgetClickTargetV2 = {
      widgetId: this.widgetId,
      calcTarget: {
        label: widget.calcArray[0].name,
        frontEndCalcId: widget.calcArray[0].id,
      },
      xValue: null,
    };
    this.emitWidgetClick.emit(clickTarget);
  }

  private renderTextWidget(widget: WidgetStateObject) {
    let autoResizeContainerHeight =
      this.componentContainer?.nativeElement?.offsetHeight || 0;
    let autoResizeContainerWidth =
      this.componentContainer?.nativeElement?.offsetWidth || 0;

    let textToRender = '';

    if (widget.widgetType === 'number' || widget.widgetType === 'line') {
      const roundTo = widget.config.rounding;
      const yValueCount = widget.calcArray?.[0]?.getActiveData()?.y?.length;
      textToRender = Number(
        widget.calcArray?.[0]?.getActiveData()?.y?.[yValueCount - 1]
          ?.values?.[0] ?? 0,
      ).toFixed(roundTo);
      if (widget.calcArray[0]?.getActiveData()?.type === 'timedelta') {
        textToRender = new TimeDelta(textToRender).toReadable();
      } else if (widget.config.abbreviateNumbers.toggled) {
        textToRender = abbreviateNumber(textToRender, roundTo);
      } else {
        textToRender = Number(textToRender).toLocaleString();
      }
      if (widget.config.dollarPrefix.toggled) {
        if (Number(textToRender) < 0) {
          textToRender = '-$' + textToRender.slice(1);
        } else {
          textToRender = '$' + textToRender;
        }
      }
      if (widget.config.percentSuffix.toggled) {
        textToRender += '%';
      }
    } else if (widget.widgetType === 'dynamicString') {
      textToRender =
        widget.calcArray[0].getActiveData()?.modal_data[0][
          widget.dynamicStringColumn
        ];
    } else if (widget.widgetType === 'staticString') {
      textToRender = widget.staticString;
    }

    let maxFontSize = 40;
    const dashboardMaxFontSize = this.store.selectSnapshot(
      (state) =>
        (state.app as IAppStateModel).appState.currentDashboard.maxFontSize,
    );
    if (dashboardMaxFontSize) {
      maxFontSize = dashboardMaxFontSize;
    }

    // The font size set in the widget
    const widgetFontSize = widget.config.fontSize;
    if (widgetFontSize) {
      maxFontSize = widgetFontSize;
    }

    // Make sure the font size isn't too big by
    // checking the height and width of the container
    // and the font size
    const containerMaxFontSize = this.getMaxFontSize(
      autoResizeContainerWidth,
      autoResizeContainerHeight,
      textToRender,
    );
    if (containerMaxFontSize < maxFontSize) {
      maxFontSize = containerMaxFontSize;
    }

    this.autoResizeStringSource$.next({
      text: textToRender,
      color: this.getNumberColor(widget),
      fontSize: maxFontSize,
    });
  }

  private getMaxFontSize(
    containerWidth: number,
    containerHeight: number,
    text: string,
  ): number {
    let minFontSize = 12;
    let maxFontSize = 200; // You might want to adjust this maximum based on your needs

    while (minFontSize < maxFontSize) {
      const testFontSize = Math.floor((minFontSize + maxFontSize) / 2);
      const testFont = `bold ${testFontSize}px muller`;

      // Create a canvas element to measure the text dimensions
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d')!;
      context.font = testFont;

      // Measure the text dimensions
      const metrics = context.measureText(text);
      const textWidth = metrics.width;
      const textHeight =
        metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
      if (
        textWidth <= containerWidth - 20 &&
        textHeight <= containerHeight - 20
      ) {
        // If the text fits, try a larger size
        minFontSize = testFontSize + 1;
      } else {
        // If the text doesn't fit, try a smaller size
        maxFontSize = testFontSize - 1;
      }
    }

    return minFontSize; // Return the largest font size that fits
  }

  private getNumberColor(widget: WidgetStateObject): string {
    // If the widget doesn't use thresholds, then the color
    // is either black or white depending on the theme
    if (widget.config.colorType.value !== 'thresholds') {
      if (widget.config.overlayCurrentNumber) {
        // The overlay number should be a contrasting color, but with a lower opacity.
        // The opacity is set to 70%.
        return themeToContrast[this.theme.currentTheme] + 'B3';
      }
      return themeToContrast[this.theme.currentTheme];
    }

    // If the widget uses thresholds, then we need to find the
    // color based on the current value
    const thresholds = widget.thresholds.custom.sort(
      (a, b) => a.value - b.value,
    );
    const currentValue =
      widget?.calcArray?.[0]?.getActiveData()?.y[0]?.values?.[0];
    let currentThresholdColor: string | null = null;
    if (currentValue) {
      for (const threshold of thresholds) {
        if (currentValue >= threshold.value) {
          currentThresholdColor = threshold.color;
        }
      }
    }
    if (!currentThresholdColor) {
      currentThresholdColor = widget.thresholds.bottom;
    }
    return currentThresholdColor;
  }

  private sortData(data: any[][], sortDirection: SortingType): any[] {
    data.forEach((row, i) => {
      switch (sortDirection) {
        case 'ascending':
          // Sort by y value from smallest to largest
          // data[key].sort((a, b) => a.value[1] - b.value[1]);
          console.log('row', row);
          data[i] = row.sort((a, b) => a.value[1] - b.value[1]);
          break;
        case 'descending':
          // Sort by y value from largest to smallest
          // data[key].sort((a, b) => b.value[1] - a.value[1]);
          data[i] = row.sort((a, b) => b.value[1] - a.value[1]);
          break;
        case 'dowMonthAsc':
        case 'dowMonthDesc':
          // Sort the x axis by day of the week, then by month, then by year, ascending.
          // The data can come is as a string like Tuesday or 2/1/2021, so we need to convert it to a Date object
          if (this.containsDaysOfWeek(row.map((item) => item.value[0]))) {
            data[i] = this.sortByDayOfWeek(row, sortDirection);
          } else {
            // Try convert the values to dates and sort them
            try {
              data[i] = row.sort((a, b) => {
                const dateA = new Date(a.value[0]);
                const dateB = new Date(b.value[0]);
                if (isNaN(dateA.getTime())) {
                  return 1; // Move null values to the end
                }
                if (isNaN(dateB.getTime())) {
                  return -1; // Move null values to the end
                }
                return dateA.getTime() - dateB.getTime();
              });
            } catch (e) {
              // If the values can't be converted to dates, sort them as strings
              data[i] = row.sort((a, b) =>
                a.value[0].localeCompare(b.value[0]),
              );
            }
          }
          break;
        default:
          // If no sorting is selected, sort by x value
          data[i] = row.sort((a, b) => {
            if (a.value[0] === null) {
              return 1;
            } else if (b.value[0] === null) {
              return -1;
            }
            // If the values are strings, sort them as strings
            if (typeof a.value[0] === 'string') {
              return a.value[0].localeCompare(b.value[0]);
            }
            // If the values are numbers, sort them as numbers
            if (typeof a.value[0] === 'number') {
              return a.value[0] - b.value[0];
            }

            // If the values are dates, sort them as dates
            const dateA = new Date(a.value[0]);
            const dateB = new Date(b.value[0]);
            if (isNaN(dateA.getTime())) {
              return 1; // Move null values to the end
            }
            if (isNaN(dateB.getTime())) {
              return -1; // Move null values to the end
            }
            return dateA.getTime() - dateB.getTime();
          });
          if (sortDirection === 'reverse') row.reverse();
      }
    });
    return data;
  }

  /**
   * Checks if the array contains any of the days of the week
   */
  private containsDaysOfWeek(arr) {
    return arr.some((item) => this.orderedDaysOfTheWeek.includes(item));
  }

  /**
   * @description First, sort the data by day of the week, month of year, then anything else
   * gets sorted in ascending order
   * @param data
   * @param sortDirection
   */
  private sortByDayOfWeek(data: any[], sortDirection: SortingType): any[] {
    const daysOfWeek = this.orderedDaysOfTheWeek;
    const otherData = data.filter(
      (item) => !daysOfWeek.includes(item.value[0]),
    );
    const daysOfWeekData = data.filter((item) =>
      daysOfWeek.includes(item.value[0]),
    );
    let sortedDaysOfWeekData;
    let sortedOtherData;
    if (sortDirection === 'dowMonthAsc') {
      sortedDaysOfWeekData = daysOfWeekData.sort(
        (a, b) =>
          daysOfWeek.indexOf(a.value[0]) - daysOfWeek.indexOf(b.value[0]),
      );
      sortedOtherData = otherData.sort((a, b) =>
        a.value[0].localeCompare(b.value[0]),
      );
    } else {
      sortedDaysOfWeekData = daysOfWeekData.sort(
        (a, b) =>
          daysOfWeek.indexOf(b.value[0]) - daysOfWeek.indexOf(a.value[0]),
      );
      sortedOtherData = otherData.sort((a, b) =>
        b.value[0].localeCompare(a.value[0]),
      );
    }
    return [...sortedDaysOfWeekData, ...sortedOtherData];
  }

  private buildMarkLines(
    thresholds: IThresholdsV2,
    prefix: string,
    suffix: string,
    abbreviate: boolean,
    roundTo: number,
    showLabel: boolean = true,
    calcDataType: string,
  ) {
    const markLines = {
      silent: true,
      symbol: 'none',
      data: [],
    };

    const data = thresholds.custom.map((threshold) => {
      return {
        yAxis: threshold.value,
        lineStyle: {
          color: threshold.color,
          type: 'dashed',
          width: 1,
        },
        label: {
          show: showLabel,
          backgroundColor: 'inherit',
          padding: 5,
          borderRadius: 5,
          fontWeight: 'bold',
          color: getContrastColor(threshold.color),
          formatter: buildLabelFormatter(
            prefix,
            suffix,
            abbreviate,
            roundTo,
            undefined,
            calcDataType,
            '',
          ),
        },
      };
    });
    markLines.data = data;
    return markLines;
  }

  private buildVisualMap(
    thresholds: IThresholdsV2,
    prefix: string,
    suffix: string,
    abbreviate: boolean,
    roundTo: number,
  ) {
    const visualMap: IVisualMap = {
      show: true,
      pieces: [],
      right: 10,
      bottom: 0,
      orient: 'horizontal',
      textStyle: {
        color: themeToContrast[this.theme.currentTheme],
      },
      formatter: buildVisualMapLabelFormatter(
        prefix,
        suffix,
        abbreviate,
        roundTo,
      ),
    };

    const sortedThresholds = thresholds.custom.sort(
      (a, b) => b.value - a.value,
    );
    const pieces: {
      gte?: number;
      lte?: number;
      lt?: number;
      color: string;
    }[] = sortedThresholds.map((threshold, index) => {
      return {
        gte: threshold.value,
        lte: sortedThresholds[index - 1]?.value || undefined,
        color: threshold.color,
      };
    });
    pieces.push({
      lte:
        sortedThresholds.length === 1
          ? sortedThresholds[sortedThresholds.length - 1]?.value - 1
          : undefined,
      lt:
        sortedThresholds.length > 1
          ? sortedThresholds[sortedThresholds.length - 1]?.value
          : undefined,
      color: thresholds.bottom,
    });
    visualMap.pieces = pieces;
    return visualMap;
  }

  private buildLegend(widget: WidgetStateObject) {
    const legendPosition = widget.config.legendPosition;
    const orientation: 'horizontal' | 'vertical' =
      legendPosition === 'top' || legendPosition === 'bottom'
        ? 'horizontal'
        : 'vertical';
    let top;
    let bottom;
    let left;
    let right;
    let width;

    // Get longest label length
    const longestLabelLength = this.getLongestLabelLength(widget);
    const maxHorizontalWidth = 150;

    if (legendPosition === 'top') {
      top = 20;
      bottom = 'auto';
    } else if (legendPosition === 'bottom') {
      top = 'bottom';
      bottom = 20;
    } else if (legendPosition === 'left') {
      left = 'left';
      right = 10;
      width = Math.min(longestLabelLength * 10, maxHorizontalWidth);
    } else if (legendPosition === 'right') {
      left = 'right';
      right = 10;
      width = Math.min(longestLabelLength * 10, maxHorizontalWidth);
    }

    // Legend options
    return {
      show: widget.config.legend.toggled,
      type: 'scroll',
      orient: orientation,
      textStyle: {
        color: themeToContrast[this.theme.currentTheme],
        width: width,
        overflow: 'truncate' as any,
        ellipsis: '...',
      },
      top: top,
      bottom: bottom,
      left: left,
      right: right,
    };
  }

  private buildGrid(widget: WidgetStateObject) {
    let top = 30;
    let bottom = Math.round(
      Math.min(
        Math.max(
          widget.config.xAxisLabelRotation === 0
            ? 5
            : widget.config.xAxisLabelRotation * 0.5,
          5,
        ),
        50,
      ),
    );
    let left = 5;
    let right = 30;
    // Check if yAxis is toggled in the widget configuration
    if (widget.config.yAxis.toggled) {
      // If yAxis is toggled, check if 'abbreviateNumbers' is also toggled
      if (widget.config.abbreviateNumbers.toggled) {
        // If 'abbreviateNumbers' is toggled, set 'left' to 20
        left = 20;
      } else {
        // If 'abbreviateNumbers' is not toggled, set 'left' to 30
        left = 30;
      }
    } else {
      // If yAxis is not toggled, set 'left' to 5
      left = 5;
    }

    if (widget.config.colorType.value === 'thresholds') {
      // Only need to move the chart to the right 50px if the chart is using thresholds
      // with a threshold marker label.
      if (
        widget.config.showThresholdMarkers &&
        !widget.config.useCalculationThresholds
      ) {
        right += 40;
      }
      bottom += 15;
    }
    if (widget.config.xLabel) {
      bottom += 20;
    }
    if (widget.config.yLabel) {
      left += 20;
    }

    // Get longest label length
    const longestLabelLength = this.getLongestLabelLength(widget);
    const maxHorizontalWidth = 150;

    const legendPosition = widget.config.legendPosition;
    const legendEnabled = widget.config.legend.toggled;
    if (legendEnabled) {
      if (legendPosition === 'top') {
        top += 40;
      } else if (legendPosition === 'bottom') {
        bottom += 20;
      } else if (legendPosition === 'left') {
        left += Math.min(longestLabelLength * 10, maxHorizontalWidth);
      } else if (legendPosition === 'right') {
        right += Math.min(longestLabelLength * 10, maxHorizontalWidth);
      }
    }

    return {
      left: left,
      right: right,
      bottom: bottom,
      top: top,
      containLabel: true,
    };
  }

  private getLongestLabelLength(widget: WidgetStateObject): number {
    const calcArray = widget.calcArray;
    let longestLabelLength = 0;
    calcArray.forEach((calc) => {
      const calcData = calc.getActiveData();
      if (calcData?.x_groupings && calcData?.x_groupings?.length) {
        const longestLabel = calcData.x_groupings.reduce((a, b) =>
          a.length > b.length ? a : b,
        );
        if (longestLabel?.length > longestLabelLength) {
          longestLabelLength = longestLabel.length;
        }
      } else if (calcData?.is_category_x_axis) {
        const longestLabel = calcData.x.reduce(
          (a, b) => (a?.length > b?.length ? a : b),
          [],
        );
        if (longestLabel?.length > longestLabelLength) {
          longestLabelLength = longestLabel.length;
        }
      } else {
        if (calc.name.length > longestLabelLength) {
          longestLabelLength = calc.name.length;
        }
      }
    });
    return longestLabelLength;
  }
}
